import { Translation } from 'react-i18next';
interface Props {
  value: string
  namespace: string
}
const Formatter = (props: Props) => {
  return <Translation ns={props.namespace}>
    {(t) => (
      <>{t(props.value)}</>
    )}
  </Translation>;
}

Formatter.defaultProps = {
  namespace: 'Default'
}

export default Formatter
