import { useState, useEffect, ReactNode, createContext } from "react";

interface IThemeContext {
  theme: string
  setTheme: (theme: string) => void
}

export const ThemeContext = createContext<IThemeContext>({
  theme: "system",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: () => {}
});


interface MediaQueryEvent {
  matches: boolean
}

const updateTheme = (theme: string) => {
  const mql = window.matchMedia('(prefers-color-scheme: dark)');
  if (theme === 'system') {
    if (mql.matches) {
      theme = 'dark'
    } else {
      theme = 'light'
    }
  }
  localStorage.setItem('DRIFT_THEME', theme)
  const body = document.body;
  if (theme === 'dark') {
    body.setAttribute('theme-mode', 'dark');
  } else {
    body.setAttribute('theme-mode', 'light');
  }
};


export const ThemeProvider = ({ children }: { children: ReactNode}) => {
  const localTheme = localStorage.getItem('DRIFT_THEME') || 'system'
  const [theme, setTheme] = useState(localTheme)
  const themeValue = { theme, setTheme: (str: string) => {
    setTheme(str)
    updateTheme(str)
  }}

  useEffect(() => {
    const mql = window.matchMedia('(prefers-color-scheme: dark)');
    function matchMode(e: MediaQueryEvent) {
      const body = document.body;
      if (e.matches) {
        // if (!body.hasAttribute('theme-mode')) {
          setTheme('dark')
          body.setAttribute('theme-mode', 'dark');
        // }
      } else {
        // if (body.hasAttribute('theme-mode')) {
          setTheme('light')
          body.setAttribute('theme-mode', 'light');
        // }
      }
    }
    mql.addEventListener('change', (e) => {
      if (!localStorage.getItem('DRIFT_THEME')) {
        matchMode(e)
      }
    });
    const localTheme = localStorage.getItem('DRIFT_THEME')
    if (!localTheme) {
      matchMode(mql)
    } else {
      matchMode({
        matches: localTheme === 'dark'
      })
    }
    
  }, [])

  return <ThemeContext.Provider value={themeValue}>
    { children }
  </ThemeContext.Provider>
}