import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// import merge from "../../utils/merge";

// Import User Languages
import zh_CN from '../../Locales/zh_CN.yaml'
import en_US from '../../Locales/en_US.yaml'
import ja_JP from '../../Locales/ja_JP.yaml'

export interface Locale {
  [key: string]: Record<string, string>| string
}

export interface LocalData {
  [key: string]: Locale
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: true,
    resources: {
      en: en_US,
      zh: zh_CN,
      ja: ja_JP
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  }).catch(() => {})

export default i18n