import { ReactNode } from "react"
import { LanguageContextProvider } from "./Language/Provider"
import { ThemeProvider } from "./Theme/Provider";
import './Framework.css'
const Framework = ({ children }: { children: ReactNode}) => {

  return (
    <ThemeProvider>
      <LanguageContextProvider defaultCode="zh-CN">
        <div className="framework">{children}</div>
      </LanguageContextProvider>
    </ThemeProvider>
  );
}

export default Framework