import { Suspense, lazy } from 'react'
import ExtraRouter from './helper'
import { RouteObject } from 'react-router-dom'
import Framework from '../Basic/Framework'
import Loading from '../Basic/Loading'

type LazyReturn = ReturnType<typeof lazy>
const routerLazyLoadingFn = (Element: LazyReturn) => <Suspense fallback={<Loading></Loading>}>
    <Element />
</Suspense>

const routesList: RouteObject[] = [
  {
    path: '/',
    element: routerLazyLoadingFn(lazy(() => import('../Pages/semi')))
  }
]

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const routes = () => {
  return <Framework>
    <ExtraRouter extraRoutes={routesList}></ExtraRouter>
  </Framework>
}
export default routes